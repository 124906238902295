<template>
  <div class="wrapper">
    <!-- Sidebar -->
    <nav
      id="sidebar"
      :expanded-action="showSidebar"
      style="min-height:100vh"
      :class="showSidebar ? '' : 'active'"
    >
      <div class="sidebar-header mt-5 pt-5">
        <h3
          class="d-lg-none"
          @click="showSidebar = !showSidebar"
        >
          Categorías
          <font-awesome-icon
            :icon="['fas', 'times']"
            class="close cursor-pointer float-right text-white f-120 mt-2"
          />
        </h3>
        <h3 class="d-none d-lg-block">
          Categorías
        </h3>
      </div>

      <ul class="list-unstyled components">
        <ApolloQuery :query="gqlCategories">
          <template slot-scope="{result:{loading, error, data}}">
            <!-- Loading -->
            <div
              v-if="loading"
              class="loading apollo text-center"
            >
              Cargando...
            </div>

            <!-- Error -->
            <div
              v-else-if="error"
              class="error apollo text-center"
            >
              Ocurrió un error
            </div>

            <!-- Result -->
            <li
              v-for="cat in data.Category_Group[0].categories"
              v-else-if="data && data.Category_Group[0].categories
                && data.Category_Group[0].categories.length"
              :key="cat.name"
              class="result apollo"
            >
              <a>
                {{ cat.name }}
              </a>
            </li>

            <!-- No result -->
            <div
              v-else
              class="no-result apollo text-center"
            >
              No hay categorías
            </div>
          </template>
        </ApolloQuery>
      </ul>
    </nav>
    <div
      id="content"
      class="mt-5 pt-4 w-100"
    >
      <h3 class="d-flex w-100 mt-2">
        <span class="pt-2 mx-auto">Revista</span>
      </h3>
      <p
        id="sidebarCollapse"
        class="text-center text-muted hover-text d-lg-none"
        style="cursor:pointer;"
        @click="showSidebar = !showSidebar"
      >
        Ver Categorías
      </p>
      <ApolloQuery
        :query="gqlRecentArticles"
        :variables="{first: 12}"
      >
        <template slot-scope="{result:{loading, error, data}}">
          <!-- Loading -->
          <div
            v-if="loading"
            class="loading apollo text-center lead blue-grey-text py-5"
          >
            Cargando...
          </div>

          <!-- Error -->
          <div
            v-else-if="error"
            class="error apollo text-center lead blue-grey-text py-5"
          >
            Ocurrió un error
          </div>

          <!-- Result -->
          <div
            v-else-if="data && data.articles && data.articles.length"
            class="result apollo"
          >
            <div class="container">
              <div class="row justify-content-center">
                <div
                  v-for="article in data.articles"
                  :key="article.id"
                  class="col-11 col-sm-9 col-md-6 col-lg-5 col-xl-4 pb-3 d-flex"
                >
                  <div class="card">
                    <img
                      class="card-img-top"
                      src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg"
                      alt="Card image cap"
                    >
                    <div
                      class="card-body"
                      style="padding-bottom:100px;"
                    >
                      <h4 class="card-title text-center mb-1">
                        {{ article.title }}
                      </h4>
                      <p
                        class="text-muted text-center text-uppercase"
                      >
                        <span
                          v-for="author in article.authors"
                          :key="author.name+author.lname"
                        >{{ author.fname }} {{ author.lname }} </span>
                      </p>
                      <p class="card-text text-justify pb-3">
                        {{ article.content }}
                      </p>
                      <div
                        class="row m-0 p-0"
                        style="position:absolute; bottom:30px; width:90%"
                      >
                        <div class="col-12 col-lg-5 d-flex j-center m-0 p-0">
                          <p class="card-text">
                            21-02-2000
                          </p>
                        </div>
                        <div class="col-12 col-lg-7 d-flex m-0 p-0">
                          <router-link :to="'/revista/'+article.id">
                            <mdb-btn color="primary-base">
                              VER MÁS
                            </mdb-btn>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row my-4">
                <div class="col">
                  <nav
                    aria-label="Page navigation example"
                  >
                    <ul class="pagination pg-blue justify-content-center">
                      <li class="page-item ">
                        <a
                          class="page-link"
                          tabindex="-1"
                        >Anterior</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link">1</a>
                      </li>
                      <li class="page-item active">
                        <a class="page-link">2
                          <span class="sr-only">(actual)</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link">3</a>
                      </li>
                      <li class="page-item ">
                        <a class="page-link">Siguiente</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <!-- No result -->
          <div
            v-else
            class="no-result apollo text-center lead blue-grey-text py-5"
          >
            No hay artículos recientes
          </div>
        </template>
      </ApolloQuery>
    </div>
  </div>
</template>
<script>
import {mdbBtn} from 'mdbvue';
import gql from 'graphql-tag';
import recentArticlesGql from '../../graphql/recentArticles.gql';
import revistaCategoriesGql from '../../graphql/revistaCategories.gql';
export default {
  name: 'Revista',
  components: {mdbBtn},
  data() {
    return {
      showSidebar: true,
      gqlRecentArticles: gql`${recentArticlesGql}`,
      gqlCategories: gql`${revistaCategoriesGql}`,
    };
  },
};
</script>
<style lang="scss">
  .wrapper {
    align-items: stretch;
    display: flex;
  }

  #sidebar {
    background: $primary-color-dark;
    color: $white;
    transition: all 0.3s;
    width: 250px;
    @media (max-width: 991px) {
      margin-left: -250px;
      position:absolute;
      z-index:1;
    }
    &.active {
      @media (max-width: 991px) {
        margin-left: 0;
      }
    }
    .sidebar-header {
      background: $primary-color-dark;
      padding: 20px;
    }
  }
  #sidebar ul.components {
    border-bottom: 1px solid $blue-darken-3;
    padding: 20px 0;
    li a {
      display: block;
      font-size: 1.1em;
      padding: 10px;
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
    li.active > a,
    a[aria-expanded="true"] {
      background: rgba(255, 255, 255, 0.1);
      color: $white;
    }
    p {
      color: $white;
      padding: 10px;
    }
  }
  .hover-text:hover{
    color: $black-base !important;
  }
  .pagination.pg-blue .page-item.active .page-link {
    background-color: $primary-color;
  }
</style>
